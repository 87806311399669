.table-container{
    .MuiTableCell-root{
        padding: 16px;
    }
}
.table-container{
    max-height: 69vh;
    overflow-y: scroll;
}
.timeDropdown{
    height: 24px;
}