@import '../../color.palette.scss';

.formStyling> select{
    display: inline-block;
    height: 21px;
}

.input{
    border-width: 1px!important;
    padding: 0px!important;
    height: 25px!important;
}

.ButtonUpdateandCancel{
    display: flex;
}

.upper_section{
    display:flex;
    
}

