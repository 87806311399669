@import '../../color.palette.scss';

.input{
    // position: absolute;
    // bottom: 0;
    // right:0;
    
    // display: inline-block;
    border-width: 1px!important;
    width:100%;
    padding: 0px!important;
    height: 25px!important;
}
.formStyling button{
    // position: absolute;
    // bottom: 0;
    // right:0;
    
    display: inline-block;
    height: 25px;

}
.formStyling> select{
    // position: absolute;
    // right:0;
    
    // bottom: 0;
    display: inline-block;
    height: 25px;
}

.formStyling{
    display:grid;
    grid-template-columns: 18.4% 16.6% 16.3% 10.8% 16.3% 20%;
    grid-gap: 3px;
}
.updateButtton{
    width: 50%;
    // background-color: $yellow;
}
.yellowBackground{
    background-color: $yellow; 
    color: black ;  
    
}

table{
    width: 100%;
}
table,th,td{
    border: 1px solid black;
    border-collapse: collapse;
}
.tablehead {
    // clo;
    font-weight: bold;
    background-color: $col7;
}

td>button{
    width: 100%;
    background-color: $yellow;
}
#ChecklistIcon{
    padding: 0px;
}
.marketType{
    // width: 65%;
    height: 25px;
    display: inline-block;
    border-width: 1px;
    padding:0px;
}
.selectionIcon{
    width: 25px;
    height: 25px;
    padding: 0px;

}
.marketSelection{
    display:grid;
    grid-template-columns: 1fr 25px;
    grid-gap: 2px;
}
// tr,td,th{
//     height: auto !important;
// }
.MuiTableCell-root{
    font-size: 11px !important;
    padding: 1px !important;
    // font-weight: bold !important;
}

.tablecontainer{
    height: 55vh;
    overflow-y: scroll;
}

.tablecontainer::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tablecontainer {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}