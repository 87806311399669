@import '../../color.palette.scss';

.customTable{
    border: none;    
}

.customTable td,
.customTable tr,
.customTable th{
    border: 1px solid black;
    border-collapse: separate;
    border-spacing: 0px;
    text-align: center;
}

.customTable th{
    border: none;
    width: 80px;
}
.customTable th:first-child{
    position: sticky;
    left:  0px;
    z-index: 4;
    width: 120px;
    min-width: 120px;
    
}

.customTable th:nth-child(2){
    position: sticky;
    left:  123.33px;
    z-index: 4;
    width: 100px;
    min-width: 100px;
}
.customTable td:first-child{
    position: sticky;
    left:  0px;
    z-index: 3;
    width: 120px;
    background-color: inherit;
    min-width: 120px;
    max-width: 120px;
    overflow-x: clip;
}
.customTable td:nth-child(2){
    position: sticky;
    left:  123.33px;
    z-index: 3;
    width: 100px;
    background-color: inherit;
    min-width: 100px;
}
.tableview {
    width: 100%; //98.5vw
    margin-top: 1vh;
    height: 64vh;
    overflow: auto;
    

    table {
        border-collapse: separate; /* Don't collapse */
        border-spacing: 0;
    }

    tr {
        height: 5vh;

        td {
            // min-width: 100px;
            z-index: 1;
            font-size: 13px;
            font-weight: bold;
            // border: 1px solid red;        
        }
    }

    th {
        background-color: rgba(89,170,203,1);
        font-size: 13px;
        color: white;
        position: sticky;
        top: 0;
        z-index: 2;
        // border: 1px solid red;
    }

    .parent_row {

        &:nth-child(8n+1),
        &:nth-child(8n+2),
        &:nth-child(8n+3),
        &:nth-child(8n+4) {
            // background-color: $col9;

            &:hover {
                // background-color: $col5;
            }
        }

        &:nth-child(8n+5),
        &:nth-child(8n+6),
        &:nth-child(8n+7),
        &:nth-child(8n+8) {
            // background-color: $col7;

            &:hover {
                // background-color: $col5;
            }
        }
    }

    .child_row {
        &:nth-child(odd) {
            background-color: $heather;

            &:hover {
                // background-color: $col5;
            }
        }

        &:nth-child(even) {
            background-color: $heather;

            &:hover {
                // background-color: $col5;
            }
        }
    }
}
.hourdata{
    padding: 2px 3px;
    min-width:35px;
}