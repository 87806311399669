@import '../../color.palette.scss';

header {
    display: flex;
    height: 60px;
    background-color: $heather;
}

.header-lft {
    display: flex;
    width: 38vw;
    align-items: center;
    background-color: $yellow;
    
}

img{
    padding-left: 20px;
    padding-right: 20px;
    border-right: 2px solid black;
}

.header-title{
    font-size: 16px;
    font-weight: bold;
    width: calc(38vw - 220px);
    text-align: center;
}

.arrow-right {
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-right: 30px solid transparent;
    border-left: 30px solid $yellow;
}

.options {
    position: absolute;
    right: 40px;
    margin: 15px;
    display: flex;
    //justify-content: space-between;
    align-items: center;

    button {
        border: none;
        background-color: inherit;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
    }
}

.dailog{
 width: 200px;
 height: 200px
}
@keyframes shake {
    10%,90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,50%,70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,60% {
    transform: translate3d(4px, 0, 0);
  }
}
