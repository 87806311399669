.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div{
        font-size: 20px;
    }
}

.graph_container {
    width: 90%;
}

.graph_container_rtm{
    width: 100%;
}