// .closeIcon{
//     // display: inline-block;
//     width: 10px;
//     height: 10px;
//     // float: right;
// }


.header{
    // position: absolute;
    // font-weight: 
    width: 100%;
    // display: flex;
    // justify-content: space-around

}
.header > div{
    display: inline-block;
    font-size: 20px;
}
.header > button{
    // display:inline-block;
    float: right;
}