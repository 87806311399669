@import '../../color.palette.scss';

.time_sec {
    display: flex;
    font-weight: 700;
    padding: 1vh 0;
    p {
        margin: 0;
        padding-left: 20px;
    }
}

.parent_div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%; //98.5vw
    min-height: 20vh;
    // height: 20vh;
    overflow-x: scroll;
    overflow-y: auto;

    .child_div{ 
        // min-width: 11vw;
        height: 17vh;
        margin-left: 0.4vw;
    }

    .top_sec {
        // width: 100%;
        height: 25%;
        text-align: center;
        border: 1px solid $black;
        line-height: calc(16vh/4);
        font-size: 14px;
        font-weight: bold;
    }
    .mid_sec,
    .bot_sec {
        // width: 100%;
        min-width: 9vw;
        height: calc((100% - 25%)/2);
        text-align: center;
        border: 1px solid $black;
        border-top: 0px solid $black;
        line-height: calc(((16vh/4)*3)/2);
        font-weight: 900;
        font-size: 20px;
        padding: 0px 5px;
    }
}