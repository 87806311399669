.input{
    // position: absolute;
    // bottom: 0;
    // right:0;
    
    // display: inline-block;
    border-width: 1px!important;
    width:100%;
    padding: 0px!important;
    height: 25px!important;
}
.alarmformStyling button{
    // position: absolute;
    // bottom: 0;
    // right:0;
    
    display: inline-block;
    height: 25px;

}
.alarmformStyling> select{
    // position: absolute;
    // right:0;
    
    // bottom: 0;
    display: inline-block;
    height: 25px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.alarmformStyling{
    display:grid;
    grid-template-columns: 16.3% 10% 14% 14% 14% 14% 16%;
    grid-gap: 3px;
    margin-bottom: 5px;
}
.updateFormButon,.cancelFormButton{
    width:50%
}
table,th,td{
    border: 1px solid black;
    border-collapse: collapse;
}
.pricesGrid{
    display: grid;
    grid-template-columns: 80% 20%;
    grid-gap: 2px;
}
.tableDivAlarm{
    max-height: 55vh;
    overflow-y: scroll;
}
.tableDivAlarm::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tableDivAlarm {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
