.AckBtnDiv{
    display:grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 10px;
}
.AckBtnDiv div{
    text-align: center;
}
.AckBtnDiv Button{
    width:50%;
}
.isSelect{
    height: auto;
    width: auto;
}
.tableDiv{
    max-height: 69vh;
    overflow-y: scroll;
}
