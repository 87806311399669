@import '../../color.palette.scss';
ul{
    list-style-type: none;
}

.Box1{
    border:1px solid black;
    width: 40%;
    margin-left: 30px;
}
.Box2{
    border:1px solid black;
    width: 40%;
    margin-left: 40px;
}
.cancel{
    background-color: $yellow;
   }