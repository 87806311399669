.AlarmColorFormStyle{
    display: grid;
    grid-template-columns: 10.4% 15% 10% 10%;
    grid-gap: 3px;
    margin-bottom: 5px
}
.AlarmColorFormStyle input{
    border-width: 1px!important;
    width:100%;
    padding: 0px!important;
    height: 25px!important;
}
.AlarmColorFormStyle Button{
    width:100%;
}
table,th,td{
    border: 1px solid black;
    border-collapse: collapse;
}
.inputColor{
    display: grid;
    grid-template-columns: 40% 60%;
    background-color: #FAFAFA;
    border-color: rgba(118, 118, 118, 0.3);
    border-width: 1px !important;
    border-style: inset;
}
.inputColor div ,.colorData div{
    text-align: center;
    padding-top: 5px;
}
.inputColor input,.colorData input{
    width: 100%;
}
.colorData{
    display: grid;
    grid-template-columns: 40% 60%;
}