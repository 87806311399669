@import './color.palette.scss';
html, body { 
  height: 100%;
}

body {
  margin: 0;
  background-color: rgb(230,242,247);
}

*{
  font-size: 11px;
  // font-family: "open-sans","sans-serif" !important;
  
}
.MuiButton-root{
  // background-color: $green !important;
  font-weight: bold !important;
  border-radius: 0px !important; 
}

input{
  height: 20px;
  width: 100px;
}

